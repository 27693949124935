import { useAuthStore } from "~/store/auth";

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();

  if (process.client) {
    if (!authStore.isAuthenticated) {
      return navigateTo("/auth/login");
    }
  }
});
